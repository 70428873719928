import { darken, lighten, Theme, ThemeOptions } from '@mui/material'

import { CaseStatus } from '@acre/graphql'

const disabledStates = [
  CaseStatus.ApplicationSubmitted,
  CaseStatus.AwaitingValuation,
  CaseStatus.AwaitingOffer,
  CaseStatus.OfferReceived,
  CaseStatus.Exchange,
  CaseStatus.Complete,
]

export const areFieldsDisabled = (caseStatus: CaseStatus) =>
  (caseStatus && disabledStates.includes(caseStatus)) || false

export const renderDynamicTheme = (selectedColour: string, baseTheme: Theme) => {
  return {
    ...baseTheme,
    colours: {
      ...baseTheme.colours,
      primary: selectedColour,
      new: {
        ...baseTheme.colours.new,
      },
    },
    palette: {
      ...baseTheme.palette,
      primary: {
        main: selectedColour,
        light: lighten(selectedColour, 0.9),
        dark: darken(selectedColour, 0.4),
        contrastText: baseTheme.colours.new.white,
      },
    },
  } as ThemeOptions
}
